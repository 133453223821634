<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <GlobalToast></GlobalToast>
    <TheSidebar/>
    <!-- <TheAside/> -->
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="fadeKey"></router-view>
            </transition>
          </CContainer>
        </main>
        <small class="app-version text-primary text-small">v{{ appVersion }}</small>
      </div>
      <!-- <TheFooter/> -->
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import GlobalToast from '@/components/GlobalToast'
//import TheFooter from './TheFooter'
// import TheAside from './TheAside'

import { mapGetters } from 'vuex';

export default {
  name: 'TheContainer',
  components: {
    GlobalToast,
    TheSidebar ,
    TheHeader/*,
    TheFooter,
    TheAside*/
  },
  computed: {
    ...mapGetters(['appVersion']),
    fadeKey() {
      const re = /(\/)?[^/]+/;
      const match = re.exec(this.$route.path);

      const key = (match != null) ? match[0] : null;

      return key;
    }
  }
}
</script>

<style scoped>
.app-version {
  position: fixed; right: 12px; bottom: 12px;  
  display: block;    
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
