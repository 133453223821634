export default
[{
  _name: 'CSidebarNav',
  _children: [
    {
      _name: 'CSidebarNavTitle',
      _children: ['Reports'],
      _rights: ["view_dashboard","view_feedback"]
    },
    /*
    {
      _name: 'CSidebarNavItem',
      name:'Dashboard',
      to: '/dashboard',
      icon: 'cil-speedometer', 
      _class: 'dashboard-page',
      _rights: ["view_dashboard"]
      ,
      // badge: {
      //   color: 'primary',
      //   text: 'NEW'
      // }
    },
    */
    {
      _name: 'CSidebarNavItem',
      name:'Feedbacks',
      to: '/feedbacks',
      icon: 'cil-star', 
      _class: 'feedback-page',
      _rights: ["view_feedback"]
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['Shipment'],
      _rights: ["view_shipment",]
    },
    {
      _name: 'CSidebarNavItem',
      name:'Shipments',
      to: '/shipments',
      icon: 'cil-truck',
      _class: 'shipments-page',
      _rights: ["view_shipment",]
    },
    // Products
    {
      _name: 'CSidebarNavTitle',
      _children: ['Products'],
      _rights: ['view_product', 'view_reward', 'edit_product']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Products',
      to: '/products/',
      icon: 'cil-cart',
      _class: 'products-page',
      _rights: ['view_product']
    },          
    {
      _name: 'CSidebarNavItem',
      name: 'Rewards',
      to: '/bonuses/',
      icon: 'cil-gift',
      _class: 'rewards-page',
      _rights: ['view_reward']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Flatfile Upload',
      to: '/products/upload',
      icon: 'cil-file',
      _class: 'uploads-page',
      _rights: ['edit_product']
    },

    // Content
    {
      _name: 'CSidebarNavTitle',
      _children: ['Content'],
      _rights: ['view_content']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Pages',
      to: '/pages/',
      icon: 'cil-spreadsheet',
      _class: 'content-page',
      _rights: ['view_content']
    },
    {
        _name: 'CSidebarNavItem',
        name: 'Footer Links',
        to: '/links/',
        icon: 'cil-spreadsheet',
        _class: 'content-links',
        _rights: ['view_content']
    },
    // Design
    {
      _name: 'CSidebarNavTitle',
      _children: ['Design'],
      _rights: ['view_content']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Design',
      icon: 'cil-wallpaper',
      to: '/general/custom',
      _class: 'design-page',
      _rights: ['view_content']
    },
    // Settings
    {
      _name: 'CSidebarNavTitle',
      _children: ['Settings'],
      _rights: ['view_settings', 'view_channel']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Channels',
      to: '/channels',
      icon: 'cil-factory',
      _class: 'channels-page',
      _rights: ['view_channel']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Automation',
      icon: 'cil-cog',
      to: '/general/',
      _class: 'automation-page',
      _rights: ['view_settings']
    },
    // Users
    {
      _name: 'CSidebarNavTitle',
      _children: ['Admin'],
      _rights: ['view_user', 'admin']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Users / Permissions',
      icon: 'cil-group',
      _rights: ['view_user', 'admin']
    }
  ]
}]