<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderNav class="main-menu">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CHeaderNav>
    <CHeaderNav class="ml-auto mr-2">      
        <TheHeaderSiteSelector></TheHeaderSiteSelector>
        <TheHeaderDropdownAccnt class="mr-1 ml-0 pl-0"/>
    </CHeaderNav>
  </CHeader>
</template>

<script>

import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderSiteSelector from './TheHeaderSiteSelector';

export default {
    name: 'TheHeader',
    components: {
        TheHeaderDropdownAccnt,
        TheHeaderSiteSelector
    }
};
</script>