<template>    
    <CHeaderNavItem class="px-3 d-flex dd-nav-item">
      <div class="mr-3 d-flex">
        <a :href="siteUrl" class="align-self-center d-flex" :target="siteUrl == '#' ? '' : '_blank'">
          <img v-if="logoFile" :src="logoFile" class="logo" :class="{half: hidden}" />
        </a>
        <CIcon v-if="hidden" name="cilLowVision" class="my-2 ml-2" title="Hidden" style="transform: translateY(2px)" />
      </div>
      <CSpinner color="secondary" v-if="!merchantId" size="sm"></CSpinner>
      <CSelect v-else class="mb-0 mr-0 pr-0" :options="options" :value="merchantId" @update:value="onSelectValue">
      </CSelect>
    </CHeaderNavItem>    
</template>

<script>
import { mapGetters } from 'vuex';
import { SELECT_SITE} from '@/store/modules/auth/mutation-types';

const mapSites = (o) => {
    return {
        label: o.name,
        value: o.merchantId
    };
}

export default {
  name: 'TheHeaderSiteSelector',
  data () {
    return { 
    }
  },
  methods: {
    onSelectValue(value) {
      this.$store.commit(SELECT_SITE, { merchantId: value});

      if (this.$router.currentRoute.path != '/') {
        this.$router.push('/');
      }
    }
  },
  computed: {
    ...mapGetters(['sites','merchantId', 'logoFile', 'hidden', 'siteUrl']),
    options() {
        return this.sites.map(mapSites);
    }
  }
}
</script>

<style scoped>
img.logo {
    width:72px; 
    height:auto; 
    max-height: 30px; 
    margin-top: 3px;
    object-fit: contain; 
    object-position: right center;
}
img.logo.half {
  opacity: 0.5;
}

.dd-nav-item {
  padding-right: 0.5rem !important;
}
</style>
