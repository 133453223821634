<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/" >    
      <img src="@/assets/images/logo-icon.png" :height="35" 
        class="c-sidebar-brand-minimized"
      />  
        <img src="@/assets/images/logo-full.png" :height="35" 
        class="c-sidebar-brand-full"    
      /> 
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="sidebarItems"/>
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import main from '@/sidebar/main';
import { mapGetters } from 'vuex';

export default {
  name: 'TheSidebar',
  components: {
  },
  data() {
    return { }
  },
  computed: {
    ...mapGetters(['permissions']),
    sidebarItems() {
      const permissions = this.permissions;
      const sidebarItems = [{
        _name: 'CSidebarNav',
        _children: main[0]._children
          .filter(c => !c._rights || c._rights.some(r => permissions.includes(r)))
      }];
      return sidebarItems;
    },
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  },
  methods: {
    findActiveClass() {
      const matches = this.$route.matched;
      if (matches) {
        for (const val of matches.slice().reverse()) {
          if (val.meta && val.meta.activeClass) {
            return val.meta.activeClass;
          }
        }
      }

      return null;
    },
    setActiveNav() {
      const activeClass = this.findActiveClass();// this.$route.meta?.activeClass;
      const active = document.getElementsByClassName("c-sidebar-nav-item");

      if (active) {
        active.forEach(element => {
          element.classList.remove("active");
        });
      }

      if (activeClass) {
        const elements = document.getElementsByClassName(activeClass);
        if (elements) {
          elements.forEach(element => {
              element.classList.add("active");
          });
        }
      }
    }
  },
  watch: {
    $route() {
      this.setActiveNav();

      const o = this;
      setTimeout(function() {
        o.setActiveNav();
      });
    }
  }
}
</script>
