<template>
    <CToaster :autohide="3000" position="top-full">
        <template v-for="toast in toasts">
        <CToast
            :key='toast.id' 
            :show="true"
            :fade="true"
            :color="toast.isError ? 'danger' : 'success'"
        >
            <h6 v-if="toast.isError">Error!</h6>
            <h6 v-else>Success!</h6>
            {{ toast.message }}
        </CToast>
        </template>
    </CToaster>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['toasts'])
    }
}
</script>