<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
  <template #toggler>
      <CHeaderNavLink>         
        <div class="c-avatar mr-2">             
          <img v-if="hasProfilePicture"
            :src="profilePicture"
            class="c-avatar-img "
          />  
          <img v-else src="https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg"       
          class="c-avatar-img "
          />
        </div>
        {{ username }}
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem to="/profile">
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>
    <CDropdownItem to="/logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>

  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
    }
  },
  computed: {
    ...mapGetters(['profilePicture', 'hasProfilePicture', 'username'])
  }  
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>